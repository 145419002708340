import { lazy } from "react";
import { Link, LinkProps, useLocation, useParams } from "react-router-dom";
import { Loading } from "therese";
import styles from "./styles.module.css";

import { useCMSTasks } from "../useTasks";

const TaskCompleted = lazy(() => import("./TaskCompleted/TaskCompleted"));
const FormTemplate = lazy(() => import("./Form/Form"));
const FeedbackTemplate = lazy(() => import("./Feedback/Feedback"));
const ApprovalTemplate = lazy(() => import("./Approval/Approval"));
const ExternalTemplate = lazy(() => import("./External/External"));
const SignatureTemplate = lazy(() => import("./Signature/Signature"));
const BookingTemplate = lazy(() => import("./Booking/Booking"));
const GuideTemplate = lazy(() => import("./Guide/Guide"));

function Task() {
  const params = useParams();
  const { cmsTasksQuery, cmsTasks } = useCMSTasks();
  const task = cmsTasks?.find((task) => task.slug === params.task_id);

  if (cmsTasksQuery.isLoading)
    return <Loading large className={styles.taskLoading} />;
  if (!task) return null;
  if (task.completed) return <TaskCompleted />;

  switch (task.cmsTask?.type) {
    case "form":
      return <FormTemplate {...task.cmsTask} />;
    case "signature":
      return <SignatureTemplate {...task.cmsTask} />;
    case "feedback":
      return <FeedbackTemplate {...task.cmsTask} />;
    case "external":
      return <ExternalTemplate {...task.cmsTask} />;
    case "approval":
      return <ApprovalTemplate {...task.cmsTask} />;
    case "booking":
      return <BookingTemplate {...task.cmsTask} />;
    case "guide":
      return <GuideTemplate {...task.cmsTask} />;
    default:
      return null;
  }
}

function TaskLink(props: LinkProps) {
  const location = useLocation();

  return <Link state={{ from: location }} {...props} />;
}

export { Task, TaskLink };
