import React from "react";
import { Button, Modal, Link, UIText, VStack } from "therese";
import { ReactComponent as CrossIcon } from "symbols/cross.svg";
import styles from "./styles.module.css";

interface IPrivacyModalProps {
  isOpen: boolean;
  dismiss: () => void;
}

const PrivacyModal: React.FC<IPrivacyModalProps> = (props) => {
  return (
    <Modal
      title="Behandling av personopplysniger"
      isOpen={props.isOpen}
      onDismiss={props.dismiss}
      size="l"
    >
      <div className={styles.modalContent}>
        <VStack spacing="m">
          <Link
            icon={CrossIcon}
            type="button"
            onClick={props.dismiss}
            className={styles.close}
          >
            Lukk
          </Link>
          <VStack spacing="l">
            <div>
              <VStack spacing="s">
                <UIText as="h3" size="28">
                  Behandling av personopplysniger
                </UIText>
                <UIText>
                  DNB Eiendom er behandlingsansvarlig og behandler ditt
                  telefonnummer og bankId for å verifisere ditt kundeforhold,
                  slik at du får tilgang til DNB Eiendoms kundeportal.
                </UIText>
                <UIText>
                  Opplysninger om deg vil være en del av de dokumenter vi etter
                  forskrift om eiendomsmegling 3-7 har oppbevaringsplikt på. Du
                  kan lese mer om hvordan DNB Eiendom behandler
                  personopplysninger, herunder om dine rettigheter til blant
                  annet innsyn, retting og sletting i{" "}
                  <UIText
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://dnbeiendom.no/om-oss/personvern"
                    underline
                  >
                    vår personvernerklæring.
                  </UIText>
                </UIText>
              </VStack>
            </div>

            <Button theme="action" size="large" onClick={props.dismiss}>
              Okei
            </Button>
          </VStack>
        </VStack>
      </div>
    </Modal>
  );
};

export default PrivacyModal;
